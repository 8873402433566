import { GetterTree } from "vuex";
import { Product, ProductsState } from "@/store/products/types";
import { RootState } from "@/store/types";

export const getters: GetterTree<ProductsState, RootState> = {
  products(state): Product[] {
    return state.products;
  },
  pagination(state) {
    return state.pagination;
  },
  total(state) {
    return state.pagination.totalItems;
  },
  selectedProduct(state) {
    return state.selectedProduct;
  },
};
