import { Module } from "vuex";
import { getters } from "@/store/products/getters";
import { actions } from "@/store/products/actions";
import { mutations } from "@/store/products/mutations";
import { ProductsState, Product } from "@/store/products/types";
import { RootState } from "@/store/types";
import { pagingDefault } from "../utils";

export const state: ProductsState = {
  products: [],
  selectedProduct: {},
  createdProductId: null,
  loading: false,
  pagination: pagingDefault,
  totalItems: 0,
};

const namespaced: boolean = true;

export const products: Module<ProductsState, RootState> = {
  namespaced,
  state: state,
  getters,
  actions,
  mutations,
};
