import { ActionTree } from "vuex";
import { ProductsState } from "@/store/products/types";
import { RootState } from "@/store/types";
import {
  deleteProductImage,
  addProductImage,
  updateProduct,
  deleteProduct,
  getProductById,
  createProduct,
  getProductsByCriteria,
  getProductsToOrder,
  getProductsGrouped,
  ProductCriteria,
  updateProductStockLevel,
} from "@/api/products";
import { pagingDefault } from "../utils";
import router from "@/router";

export const actions: ActionTree<ProductsState, RootState> = {
  async getProducts(
    { commit, dispatch, state },
    payload: { body: ProductCriteria; showDisabled: false }
  ) {
    try {
      commit("loading");
      const result = await getProductsByCriteria(
        payload.body,
        state.pagination.page,
        state.pagination.itemsPerPage,
        payload.showDisabled,
        "Marketplace"
      );
      commit("setProducts", result.data.results);
      commit("setTotal", result.data.count);
    } catch (error) {
      dispatch("notifications/error", error, { root: true });
      commit("loading", false);
    }
  },

  async getProductsGrouped(
    { commit, dispatch, state },
    payload: { page: number; pagesize: number }
  ) {
    try {
      commit("loading");
      const result = await getProductsGrouped(
        payload.page,
        payload.pagesize,
        "Marketplace"
      );
      const categories = result.data.results.map((item: any) => {
        return { id: item.id, name: item.name };
      });

      return categories;
    } catch (error) {
      dispatch("notifications/error", error, { root: true });
      commit("loading", false);
    }
  },

  async getProductsToOrder(
    { commit, state },
    payload: { body: ProductCriteria; connectionId: number }
  ) {
    try {
      commit("loading");
      const result = await getProductsToOrder(
        payload.body,
        state.pagination.page,
        state.pagination.itemsPerPage,
        payload.connectionId,
        "Marketplace"
      );
      commit("setProducts", result.data.results);
      commit("setTotal", result.data.count);
    } catch (error) {
      // no connection exists
      router.push({ name: "my-customers" });
    }
  },

  async getProductById({ commit, dispatch }, productId: number) {
    try {
      commit("loading");
      const result = await getProductById(productId);
      commit("setSelectedProduct", result.data);
    } catch (error) {
      dispatch("notifications/error", error, { root: true });
      commit("loading", false);
    }
  },

  async createProduct({ commit, dispatch }, productData) {
    try {
      commit("loading");
      const response = await createProduct(productData);
      let regex =
        /(http|ftp|https):\/\/[a-z-.]*(\/resource\/product\/organisation\/)\d+\//g;
      let location = response.headers.location;
      let prodId = location.replace(regex, "");
      commit("setCreatedProduct", prodId);
      commit("notifications/success", "Product Created", { root: true });
    } catch (error) {
      dispatch(
        "notifications/error",
        { message: "Could not create product", error },
        { root: true }
      );
      commit("loading", false);
      throw "";
    }
  },

  async deleteProduct({ commit, dispatch }, productId) {
    try {
      commit("loading");
      await deleteProduct(productId);
      commit("notifications/success", "Product Deleted", { root: true });
      return true;
    } catch (error) {
      dispatch(
        "notifications/error",
        { message: "Could not delete product", error },
        { root: true }
      );
      commit("loading", false);
    }
  },

  async productUpdate(
    { commit, dispatch },
    data: { body: any; inline: boolean }
  ) {
    try {
      if (!data.inline) {
        commit("loading");
      }

      let tags = { ...data.body.tags, ...data.body.productLabels };
      // convert object to array
      data.body.tags = Object.keys(tags).map(function (key) {
        return tags[key];
      });
      // data.body.tags = Object.entries({ ...data.body.tags, ...data.body.productLabels }); @todo: check this to make it work

      // delete this field as api does not support it
      delete data.body.productLabels;

      const result = await updateProduct(data.body);
      if (result.status === 200) {
        commit("notifications/success", `Product ${data.body.name} updated`, {
          root: true,
        });
      }
      if (!data.inline) {
        commit("loading", false);
      }
      return true;
    } catch (error) {
      dispatch(
        "notifications/error",
        { message: "Could not update product", error },
        { root: true }
      );
      commit("loading", false);
    }
  },

  async productStockLevelUpdate({ commit, dispatch }, stockLevelData) {
    try {
      commit("loading");
      await updateProductStockLevel(
        stockLevelData.productId,
        stockLevelData.quantity
      );
      commit("notifications/success", "Product stock level updated", {
        root: true,
      });
      return true;
    } catch (error) {
      dispatch(
        "notifications/error",
        { message: "Could not update product", error },
        { root: true }
      );
      commit("loading", false);
    }
  },

  async addProductImage(
    { commit, dispatch },
    imageObject: { productId: number; formData: any }
  ) {
    try {
      commit("loading");
      await addProductImage(imageObject.productId, imageObject.formData);
      commit("notifications/success", "New Image Uploaded.", { root: true });
      return true;
    } catch (error) {
      dispatch(
        "notifications/error",
        { message: "Could not upload image", error },
        { root: true }
      );
      commit("loading", false);
    }
  },

  async deleteProductImage(
    { commit, dispatch },
    deleteObject: { productId: number; imageName: string }
  ) {
    try {
      commit("loading");
      await deleteProductImage(deleteObject.productId, deleteObject.imageName);
      commit("notifications/", "Image has been deleted!", { root: true });
      return true;
    } catch (error) {
      dispatch(
        "notifications/error",
        { message: "Could not delete image", error },
        { root: true }
      );
      commit("loading", false);
    }
  },

  paginate({ commit }, pagination) {
    commit("paginate", pagination);
  },

  refresh({ dispatch }) {
    dispatch("paginate", pagingDefault);
  },
};
