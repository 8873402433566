import { MutationTree } from "vuex";
import { ProductsState } from "@/store/products/types";

export const mutations: MutationTree<ProductsState> = {
  setProducts(state, payload) {
    state.products = payload;
    state.loading = false;
  },

  setSelectedProduct(state, payload) {
    state.selectedProduct = payload;
    state.loading = false;
  },

  setCreatedProduct(state, payload) {
    state.createdProductId = payload;
    state.loading = false;
  },

  loading(state, payload = true) {
    state.loading = payload;
  },

  /* Paging */
  paginate(state, payload) {
    state.pagination = JSON.parse(JSON.stringify(payload));
  },
  setTotal(state, payload) {
    state.totalItems = payload;
  },
};
